@layer modules, ui, base;
@layer ui {
  /* @define LayoutColumn */

.layouColumn_jds-LayoutColumn__QufQy {
  box-sizing: border-box;
  width: 100%;
  /*
  "min-width: 0" is used to prevent the carousel component to overflow and give a too big size at the layout column
  We cannod use a simple "overflow-x: hidden" on the layout cause we need to display the arrows which have a light overflow on the layout column
  */
  min-width: 0;
  margin: var(--spacer-100) var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:first-child {
  margin-left: var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:last-child {
  margin-right: var(--Layout-column-margin-size);
}

@media (min-width: 48rem) {
  .layouColumn_jds-LayoutColumn__QufQy {
    flex: 1;
    margin: 0;
  }

  .layouColumn_jds-LayoutColumn--fixedWidth__JCI_8 {
    --LayoutColumn-FixedWidth: calc(var(--Layout-column-size) * var(--layout-column-number) + ( var(--layout-column-number) - 1 ) * var(--Layout-column-gutter-size));
    flex: 0 0 var(--LayoutColumn-FixedWidth);
  }
}

}
@layer ui {
  /** @define Layout */

.layout_jds-Layout__hFF0F {
  --Layout-columnsBase: 12;
  --Layout-size: 100%;
  --Layout-padding: calc((100% - var(--Layout-size)) / 2);
  --Layout-column-gutter-size: var(--spacer-200);
  --Layout-column-margin-size: 1.25rem; /* 20px (specific spacer for Layout) */
  --Layout-column-size: calc(( ( var(--Layout-size) - var(--Layout-column-margin-size) * 2 ) - var(--Layout-column-gutter-size) * ( var(--Layout-columnsBase) - 1) ) / var(--Layout-columnsBase));

  display: flex;
  box-sizing: content-box; /* Fix box sizing to override some style coming from legacy component */
  flex-wrap: wrap;
  width: var(--Layout-size);
  padding: 0 var(--Layout-padding);
}

.layout_jds-Layout--isBo__hWLcI {
  --Layout-size: calc(100% - var(--jds-bo-width-delta, 0px));
}

.layout_jds-Layout--isFullWidth__Lkbb3 {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.layout_jds-Layout--topPadding__vMye5 {
  padding-top: var(--spacer-400);
}

.layout_jds-Layout--bottomPadding__sa1oV {
  padding-bottom: var(--spacer-400);
}

.layout_jds-Layout--greyBlock__k6wDa {
  background: var(--color-grey--025);
}

.layout_jds-Layout--blackBlock__kQo1N {
  background: var(--color-black);
  color: var(--color-white);
}

.layout_jds-Layout--whiteBlock__KxtWG {
  background: var(--color-white);
}

.layout_jds-Layout--separator__6Jcn_ {
  border-bottom: 1px solid var(--color-grey--200);
}

@media (min-width: 48rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--medium-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);

    flex-wrap: initial;
    gap: var(--Layout-column-gutter-size);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--medium-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-600);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-600);
  }
}

@media (min-width: 64rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--large-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);
    --Layout-column-margin-size: var(--spacer-300);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--large-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-800);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-800);
  }

  .layout_jds-Layout--isSmall__03qdy {
    --Layout-paddingIsSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 2);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
  }

  .layout_jds-Layout--isExtraSmall__Hf5k8 {
    --Layout-paddingIsExtraSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 3);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsExtraSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
  }
}

@media (min-width: 80rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--major-breakpoint);
    --Layout-column-gutter-size: var(--spacer-400);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--major-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-500);
  }
}

@media (min-width: 90rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--huge-breakpoint);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--huge-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

@media (min-width: 120rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--extra-breakpoint);

    max-width: var(--Layout-size);
    padding-right: var(--Layout-padding);
    padding-left: var(--Layout-padding);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--extra-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

}
@layer ui {
  /** @define Pictogram */
.Pictogram_jds-Pictogram__ZG8GF {
  fill: currentcolor;
  vertical-align: middle;
}

.Pictogram_jds-Pictogram--minor__wQY3C {
  width: var(--picto-size-minor); /* 36px */
  height: var(--picto-size-minor); /* 36px */
}

.Pictogram_jds-Pictogram--small__0SrfD {
  width: var(--picto-size-small); /* 48px */
  height: var(--picto-size-small); /* 48px */
}

.Pictogram_jds-Pictogram--medium__z2GTT {
  width: var(--picto-size-medium); /* 60px */
  height: var(--picto-size-medium); /* 60px */
}

.Pictogram_jds-Pictogram--large__TFbj1 {
  width: var(--picto-size-large); /* 96px */
  height: var(--picto-size-large); /* 96px */
}

.Pictogram_jds-Pictogram--major__PoFzE {
  width: var(--picto-size-major); /* 112px */
  height: var(--picto-size-major); /* 112px */
}

}
@layer ui {
  /** @define ElementMessage */

.elementMessage_jds-ElementMessage__UF2hh {
  --ElementMessage-maxWidth: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: nowrap;
  width: var(--ElementMessage-maxWidth);
  max-width: 100%;
  padding: var(--spacer-200);
  border: 1px solid var(--color-grey--100);
  border-radius: var(--radius-200);
  background-color: var(--color-grey--025);
}

.elementMessage_jds-ElementMessage--success___RllK {
  border-color: var(--color-success--500);
  background-color: var(--color-success--100);
}

.elementMessage_jds-ElementMessage--warning__QHChg {
  border-color: var(--color-warning--500);
  background-color: var(--color-warning--100);
}

.elementMessage_jds-ElementMessage--error__rxXZt {
  border-color: var(--color-error--500);
  background-color: var(--color-error--100);
}

.elementMessage_jds-ElementMessage--info__qN1dC {
  background-color: var(--color-grey--100);
}

.elementMessage_jds-ElementMessage--asNotification__oMCV1 {
  border: none;
  opacity: 1;
  box-shadow: var(--default-shadow);
}

.elementMessage_jds-ElementMessage--unshownNotification__Q7upJ {
  animation: elementMessage_unshown__Xg6Fo 0.2s ease-in forwards;
}

.elementMessage_jds-ElementMessage__content__3zrQF {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0 var(--spacer-100);
}

.elementMessage_jds-ElementMessage__content__3zrQF::first-letter {
  text-transform: uppercase;
}

.elementMessage_jds-ElementMessage__title__QHDIw {
  font-weight: var(--semiBold);
}

.elementMessage_jds-ElementMessage__text__vIAHS :not([role="heading"]) {
  font-size: var(--smallBody);
}

.elementMessage_jds-ElementMessage__symbol__eHpLc {
  width: var(--icon-size-medium);
}

.elementMessage_jds-ElementMessage__symbol--info__fx2yl {
  color: var(--color-grey--500);
}

.elementMessage_jds-ElementMessage__symbol--success__zq6eG {
  color: var(--color-black);
}

.elementMessage_jds-ElementMessage__symbol--warning__1BYM5 {
  color: var(--color-warning--500);
}

.elementMessage_jds-ElementMessage__symbol--error__6C8LH {
  color: var(--color-error--500);
}

.elementMessage_jds-ElementMessage__symbol--isPictogram__jVhxI {
  width: var(--picto-size-small);
}

.elementMessage_jds-ElementMessage__close__ujD2X {
  align-self: flex-start;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  color: var(--color-black);
  cursor: pointer;
}

@media (min-width: 48rem) {
  .elementMessage_jds-ElementMessage--asNotification__oMCV1 {
    --ElementMessage-maxWidth: 35.25rem; /* 564px */
  }
}

@keyframes elementMessage_unshown__Xg6Fo {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

}
@layer ui {
  .sk-Badge_main__AVFxz {
  --sk-Badge-y-padding: var(--sk-space-4);
  --sk-Badge-x-padding: var(--sk-space-8);
  --sk-Badge-label-spacing: var(--sk-space-4);
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-m-line-height));

  display: inline-flex;
  box-sizing: border-box;
  flex-grow: 0;
  align-items: center;
  align-self: end;
  justify-content: center;
  min-width: 0; /* Required for overflow: ellipsis; */
  height: var(--sk-Badge-height);
  padding: var(--sk-Badge-y-padding) calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  overflow: hidden;
  border-radius: var(--sk-radius-20);
  font-family: var(--sk-typography-data-display-badge-text-typography-m-font-family);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  gap: var(--sk-Badge-label-spacing);
}

.sk-Badge_label__f1crk {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.sk-Badge_icon__M8ilK {
  flex-shrink: 0;
}

.sk-Badge_main--withIcon__uFfRO {
  padding-right: calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  padding-left: var(--sk-Badge-x-padding);
}

.sk-Badge_main--medium__d1Pq_ {
  font-size: var(--sk-typography-data-display-badge-text-typography-m-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-m-line-height);
}

.sk-Badge_main--large__WUCcE {
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-l-line-height));

  font-size: var(--sk-typography-data-display-badge-text-typography-l-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-l-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-l-line-height);
}

.sk-Badge_main--tertiary__J_3Du {
  border-width: var(--sk-data-display-badge-size-border-tertiary);
  border-style: solid;
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-light);
  color: var(--sk-data-display-badge-color-primary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-light);
  color: var(--sk-data-display-badge-color-primary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-light);
  color: var(--sk-data-display-badge-color-secondary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-light);
  color: var(--sk-data-display-badge-color-secondary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-light);
  background-color: var(--sk-data-display-badge-color-background-tertiary-light);
  color: var(--sk-data-display-badge-color-tertiary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-light);
  color: var(--sk-data-display-badge-color-success-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-light);
  color: var(--sk-data-display-badge-color-success-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-light);
  color: var(--sk-data-display-badge-color-warning-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-light);
  color: var(--sk-data-display-badge-color-warning-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-light);
  color: var(--sk-data-display-badge-color-error-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-light);
  color: var(--sk-data-display-badge-color-error-faded-light);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-dark);
  color: var(--sk-data-display-badge-color-primary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-dark);
  color: var(--sk-data-display-badge-color-primary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-dark);
  color: var(--sk-data-display-badge-color-secondary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-dark);
  color: var(--sk-data-display-badge-color-secondary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-dark);
  background-color: var(--sk-data-display-badge-color-background-tertiary-dark);
  color: var(--sk-data-display-badge-color-tertiary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-dark);
  color: var(--sk-data-display-badge-color-success-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-dark);
  color: var(--sk-data-display-badge-color-success-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-dark);
  color: var(--sk-data-display-badge-color-warning-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-dark);
  color: var(--sk-data-display-badge-color-warning-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-dark);
  color: var(--sk-data-display-badge-color-error-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-dark);
  color: var(--sk-data-display-badge-color-error-faded-dark);
}

}
@layer ui {
  /** @define MenuTag */

.MenuTag_jds-MenuTag__Rju_e {
  margin-right: var(--spacer-50);
  padding: 0 var(--spacer-50);
  border: 1px solid var(--color-grey--025);
  border-radius: var(--radius-200);
  background: var(--color-white);
  color: var(--color-magenta--500);
  font-size: var(--minorBody);
  font-weight: var(--bold);
  line-height: var(--minorBodyLineHeight);
  text-transform: uppercase;
}

}
@layer ui {
  /** @define MenuItem */

.MenuItem_jds-MenuItem__MRXWJ {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 0 0 var(--spacer-200);
  border: none;
  outline: 0;
  background: none;
  color: var(--color-black);
  font-family: var(--fontFamily);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  text-decoration: none;
  cursor: pointer;
}

.MenuItem_jds-MenuItem__MRXWJ::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: var(--radius-200);
}

.MenuItem_jds-MenuItem__MRXWJ:not(.MenuItem_jds-MenuItem--selected__91sFZ):hover,
.MenuItem_jds-MenuItem__MRXWJ:not(.MenuItem_jds-MenuItem--selected__91sFZ):focus {
  border-color: var(--color-grey--200);
}

.MenuItem_jds-MenuItem--selected__91sFZ {
  border-color: var(--color-black);
  font-weight: var(--semiBold);
}

.MenuItem_jds-MenuItem--selected__91sFZ::after,
.MenuItem_jds-MenuItem__MRXWJ:hover::after {
  background-color: var(--color-black);
}

}
@layer ui {
  /** @define RadioButton */
.radioButton_jds-RadioButton__S8dNK {
  --RadioButton-diameter: 1.25rem; /* 20px */
  display: inline-flex;
  align-items: center;
  color: var(--color-black);
  font-size: var(--normalBody);
  line-height: var(--normalBodyLineHeight);
  cursor: pointer;
}

.radioButton_jds-RadioButton__input__3wDSo {
  box-sizing: border-box;
  flex-shrink: 0;
  width: var(--RadioButton-diameter);
  height: var(--RadioButton-diameter);
  margin: 0;
  border: 1px solid var(--color-grey--200);
  border-radius: var(--radius-900);
  outline: 0;
  background-color: var(--color-white);
  vertical-align: text-bottom;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.radioButton_jds-RadioButton__input--withLabel__t6GcE {
  margin-right: var(--spacer-100);
}

/* "hover" and "focus" states */

.radioButton_jds-RadioButton__input__3wDSo:not(:disabled):hover,
.radioButton_jds-RadioButton__input__3wDSo:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

/* "checked" state */

.radioButton_jds-RadioButton__input__3wDSo:checked {
  position: relative;
  border-color: var(--themeMainColor, currentcolor);
  color: var(--color-black);
}

.radioButton_jds-RadioButton__input__3wDSo:checked::after {
  content: "";
  position: absolute;
  top: calc(50% - var(--RadioButton-diameter) / 4);
  left: calc(50% - var(--RadioButton-diameter) / 4);
  width: calc(var(--RadioButton-diameter) / 2);
  height: calc(var(--RadioButton-diameter) / 2);
  border-radius: var(--radius-900);
  background-color: var(--themeMainColor, currentcolor);
}

/* "disabled" state */

.radioButton_jds-RadioButton--isDisabled__W7Ix0 {
  color: var(--color-grey--200);
  cursor: default;
}

.radioButton_jds-RadioButton__input__3wDSo:disabled,
.radioButton_jds-RadioButton__input__3wDSo:disabled:checked::after {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--100);
  cursor: default;
}

.radioButton_jds-RadioButton__input__3wDSo:disabled:checked::after {
  background-color: var(--color-grey--200);
}

}
@layer ui {
  /* @define Tag */

.Tag_jds-Tag__S_kl6 {
  --Tag-radius: var(--radius-200);
  /* Needed here to prevent calc() with 0 (without unit) which doesn't work. */
  /* stylelint-disable-next-line length-zero-no-unit */
  --Tag-removable-button-width: 0rem;
  /* medium by default */
  --Tag-horizontal-spacing: var(--spacer-150);
  --Tag-vertical-spacing: var(--spacer-50);

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: var(--Tag-vertical-spacing) var(--Tag-horizontal-spacing);
  border-radius: var(--Tag-radius);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.Tag_jds-Tag--small__645vS {
  --Tag-horizontal-spacing: var(--spacer-100);
  --Tag-vertical-spacing: 0;

  font-size: var(--smallBody);
}

.Tag_jds-Tag--large__bg77j {
  --Tag-horizontal-spacing: var(--spacer-200);
  --Tag-vertical-spacing: var(--spacer-100);
}

.Tag_jds-Tag--medium__svQfh,
.Tag_jds-Tag--large__bg77j {
  font-size: var(--normalBody);
}

.Tag_jds-Tag--withIcon__zJH9U {
  padding-left: var(--spacer-100);
}

.Tag_jds-Tag--removable__KmanL[class*="small"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-small) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="medium"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-medium) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="large"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-big) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL {
  /* margin-left + Remove icon size */
  padding-right: calc(var(--spacer-50) + var(--Tag-removable-button-width));
}

.Tag_jds-Tag__Icon__XzZfg {
  margin-right: var(--spacer-100);
}

.Tag_jds-Tag__Label__WNaZc {
  /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Tag_jds-Tag__RemoveButton__yNPo2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--Tag-removable-button-width);
  padding-right: var(--spacer-100);
  padding-left: var(--spacer-50);
  border: 0;
  border-top-right-radius: var(--Tag-radius);
  border-bottom-right-radius: var(--Tag-radius);
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.Tag_jds-Tag__RemoveButton__yNPo2:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Tag_jds-Tag__RemoveButton__yNPo2:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.Tag_jds-Tag--cctheme__CP2rD .Tag_jds-Tag__RemoveButton__yNPo2 {
  color: var(--color-white);
}

/* @TODO: handle colors variant here */

.Tag_jds-Tag--default__4MLCo {
  background-color: var(--color-grey--100);
  color: var(--color-grey--700);
}

.Tag_jds-Tag--cctheme__CP2rD {
  background-color: var(--mainColor);
  color: var(--color-black);
}

.Tag_jds-Tag--purple__o_4tb {
  background-color: var(--color-purple--100);
  color: var(--color-purple--700);
}

.Tag_jds-Tag--warning__f1lkM {
  background-color: var(--color-warning--100);
  color: var(--color-warning--700);
}

.Tag_jds-Tag--pink__sY_QO {
  background-color: var(--color-pink--100);
  color: var(--color-pink--800);
}

.Tag_jds-Tag--cyan__ZpEK6 {
  background-color: var(--color-cyan--050);
  color: var(--color-cyan--800);
}

.Tag_jds-Tag--yellow__A7uJy {
  background-color: var(--color-yellow--050);
  color: var(--color-yellow--800);
}

.Tag_jds-Tag--dark__7gaeu {
  background-color: var(--color-grey--700);
  color: var(--color-white);
}

}
@layer ui {
  /** @define Field */

.field_jds-Field__label__2NFTo {
  display: block;
  margin-bottom: var(--spacer-50);
  font-size: var(--body2);
  font-weight: var(--semiBold);
  line-height: var(--body2-lh);
}

.field_jds-Field__label--isResized__93_ei {
  transform: translateY(6px);
  font-size: var(--minorBody);
  line-height: var(--minorBodyLineHeight);
}

.field_jds-Field__label--disabled__zlX5R {
  color: var(--color-grey--200);
}

.field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--600);
  font-weight: var(--normal);
}

.field_jds-Field__notRequiredText__bfS7o::before {
  content: "-";
  margin: 0 var(--spacer-50);
}

.field_jds-Field__label--disabled__zlX5R .field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--200);
}

.field_jds-Field__inputContainer__OUGsz {
  position: relative;
  width: 100%;
  min-height: var(--field-height);
}

.field_jds-Field__iconWrapper__1YDn6 {
  position: absolute;
  top: 50%;
  right: var(--spacer-200);
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: transparent;
  color: var(--color-black);
}

.field_jds-Field__iconWrapper--left__vGTS1 {
  right: auto;
  left: var(--spacer-200);
}

.field_jds-Field__iconWrapper--disabled__WshVl {
  color: var(--color-grey--200);
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl) {
  color: var(--color-grey--200);
  cursor: pointer;
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl):hover {
  color: var(--color-black);
}

.field_jds-Field__icon__YwksS {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}

}
@layer ui {
  /** @define FieldContainer */

.fieldContainer_jds-FieldContainer__ZIUSU {
  display: block;
  position: relative;
  min-width: 0; /* reset default overflow content behavior of a fieldset */
  margin: 0 0 var(--spacer-200);
  padding: 0;
  border: none;
  color: var(--color-black);
  font-size: var(--normalBody);
}

/* stylelint-disable plugin/selector-bem-pattern */
.fieldContainer_jds-FieldContainer--disabled__s1R2l .fieldContainer_jds-Field__notRequiredText__gIF9c {
  color: inherit;
}
/* stylelint-enable */

.fieldContainer_jds-FieldContainer__switchText__Tbcki {
  padding-top: var(--spacer-50);
  font-weight: var(--normal);
}

}
